import React from 'react';
import { Fragment } from 'react';
import { Link } from "react-router-dom";
import useWindowDimensions from '../../Hooks/useWindowDimensions';
// import Logo from '../../Assets/Images/logo.svg';
import Logo_M from '../../Assets/Images/Logo_Mobile.png';
// import BleatLogo from '../../Assets/Images/logo_bleat_black_text.png';
// import churchOutreachLogo from '../../Assets/Images/logo-outreach.png';
import sheepLogo from "../../Assets/Images/sheep.png";
import logoline1 from "../../Assets/Images/bleat.svg";
import logoline2 from "../../Assets/Images/ALIGNER.svg";
import leftLogo from "../../Assets/Images/left-logo.png";
// import BleatLogo from "../../Assets/Images/logo-bleat-new.png";
import BleatLogo from "../../Assets/Images/built-by-bleat-vertical-light.png";

const Header = props => {

  const { windowWidth } = useWindowDimensions();
  
  return (
  <Fragment>
    <header>
      {/* <Link to="/start" className="logo"><img src={Logo} /></Link> */}
      {/* <div className="assessment-title" id="assessment-title">
        { windowWidth > 830 ? <img src={Logo} alt="ELS Logo" className='leadership'/> : <img src={Logo_M} alt="ELS Logo" className='mob_lead' />}
        { windowWidth > 650 ? <p>Clear Path Forward: Pre-Work Assessment</p> : <p>Clear Path Forward: Pre-Work Assessment</p>}
        { windowWidth > 830 ? <img src={BleatLogo} alt="Bleat Logo" /> : null}
      </div> */}
      <div className="logo-left">
					<img className="logo-sheep" src={leftLogo} alt="Bleat Aligner" />
					{/* <div className="logo-text">
						<img src={logoline1} className="logo-line-1" alt="" />
						<img className="logo-line-2" src={logoline2} alt="" />
					</div> */}
				</div>
				<p className="assessment-title">The Alignment Assessment</p>
				<div className="logo-right" style={{ width: "78px", height: "48px" }}>
					{/* <img src={BleatLogo} alt="" /> */}
				</div>
    </header>
  </Fragment>
  );
}

export default Header;
