import React, { useState, useEffect } from 'react';
import ReactDOMServer from "react-dom/server";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link, useParams, useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import testimonialImage from '../Assets/Images/testimonial.jpg';
import quotationImage from '../Assets/Images/quotation.svg';

import insightsIcon from '../Assets/Images/insights-icon.png';
import alignmentIcon from '../Assets/Images/alignment-icon.png';
import directionIcon from '../Assets/Images/direction-icon.png';
import downloadIcon from '../Assets/Images/download-icon.svg';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import useWindowDimensions from '../Hooks/useWindowDimensions';

import '../App.scss';
// import ResultBarRainbow from '../Components/Results/ResultBarRainbow';
import ResultsSectionBarGraph from '../Components/Results/ResultsSectionBarGraph';
import InviteInfo from './InviteInfo';
import Testimonial from './Testimonial';


const Results = (props) => {

  let navigate                        = useNavigate();
  const { id }                        = useParams();
  const stuff = useParams();
  console.log(stuff);

  const { windowWidth }               = useWindowDimensions();
  const [isLoading, setIsLoading]     = useState(true);
  const [apiData, setApiData]         = useState(null);
  const [pdfClass, setPdfClass]       = useState(false);
  const [isMember, setIsMember]   = useState(false);
  const [name, setName]           = useState("");
  const [score, setScore]         = useState(0);
  const [sections, setSections]   = useState([]);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScores, setSubScores] = useState([0, 0, 0, 0]);

  useEffect(() => {
    // document.body.style.backgroundColor = "#1F2A44";
    // document.getElementById('assessment-title').style.color = "#FBF9F2";
    fetchResults();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSubScores([subScore1, subScore2, subScore3, subScore4]);
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [subScore1, subScore2, subScore3, subScore4])

  const fetchResults = () => {

    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id, {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
      console.log(data);
			if (data.status === "success") {
				setIsLoading(false);
        setApiData(data);
        setName(data.name);
        setScore(data.score);
        setSections(data.sections);
        setSubScore1(data.subScore1);
        setSubScore2(data.subScore2);
        setSubScore3(data.subScore3);
        setSubScore4(data.subScore4);
        setIsMember(data.isTeamMember);
			} else {
				// setOpenSnackbar(true);
			}
		});
  }

  const source = apiData?.source;
  console.log("source", source);

  // const subScore1 = parseInt(apiData?.subScore1);
  // const subScore2 = parseInt(apiData?.subScore2);
  // const subScore3 = parseInt(apiData?.subScore3);
  // const subScore4 = parseInt(apiData?.subScore4);
  const subScore5 = parseInt(apiData?.subScore5);
  const subScore6 = parseInt(apiData?.subScore6);
  const subScore7 = parseInt(apiData?.subScore7);
  const subScore8 = parseInt(apiData?.subScore8);
  const subScore9 = parseInt(apiData?.subScore9);

  // console.log("apiData", apiData);
  const subscores = { subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8, subScore9 };

  // console.log("maxx", (Math.max(parseInt(Object.values(subscores)))));
  // console.log("sorted", (Array.from(Object.values(subscores))));

  const scoresAndText = [
    { id: 1, title: "Strategy", score: subScore1, 
      resultTitle: "your greatest area for growth is Experience.", 
      resultText: ["A more integrated approach to Sunday morning services and daily digital engagement could yield a higher level of overall engagement as you build closer relationships and meaningful discipleship pathways.", "This requires thinking about tailoring the experiences you are creating to maximize the medium of experience, tailoring each experience to what it is meant to accomplish."] },
    { id: 2, title: "Tools", score: subScore2, 
      resultTitle: "your greatest area for growth is Leadership.", 
      resultText: ["Alignment in leadership outweighs the individual clarity of its leaders, as clarity is most meaningful when everyone has the same vision and is pointed in the same direction.", "For your church to maximize its opportunities in leading people closer to Jesus, leadership has to be completely aligned and clear in both why your church exists and who you are uniquely created to serve (identity) before it can move onto strategy."] },
    { id: 3, title: "Lead Gen", score: subScore3, 
      resultTitle: "your greatest area for growth is Data.", 
      resultText: ["The better you are at understanding who it is you are trying to reach and how to reach them, your church needs to have clear metrics and data to constantly evaluate what is working and what is not.", "Data does not create answers but it does help us form the questions that inform our decisions. Constant evaluation and course correction accelerates our ability to create meaningful relationships, which is much harder when clear data is not available to us."] },
    { id: 4, title: "Data", score: subScore4, 
      resultTitle: "your greatest area for growth is Development.", 
      resultText: ["Money and buildings are great servants, but terrible masters. The ability for money to be a servant to your ministry and not dictate the decisions you have to make is based on your ability to create and execute financial and real estate strategy.", "For your church to be successful in using its assets to accelerate ministry, we have to consider how we are utilizing our biggest assets."] },
  ];

  const sortedScoresAndText = scoresAndText.sort((a, b) => b.score - a.score);
  console.log("sortedScoresAndText", sortedScoresAndText);

  const showTitleText = () => {
    // if(score > 70) return <h1>Your church exceeds expectations</h1>
    // else if(score > 45) return <h1>Your church meets expectations</h1>
    // else return <h1>Your church needs improvement</h1>
    // return <h1>{apiData?.name?.trim()}, {sortedScoresAndText?.[0]?.resultTitle}</h1>
    return <h1>{apiData?.name?.trim()}, your alignment score is... </h1>
  }

  const showResultText = () => {
    return sortedScoresAndText?.[0]?.resultText?.map(text => <p>{text}</p>)
  }

  const showScoreText = () => {
    // if (score > 0 && score <= 45) {
    if (score >= 0 && score <= 50) {
      return <div className="result-text">
        <p>It looks like you need help, but don't worry! By working on your <strong>{sortedScoresAndText[3].title?.toLowerCase()}</strong> you'll be on your way to aligning church teams better toward your solution.</p>        
      </div>
    // } else if (score > 45 && score <= 70) {
    } else if (score > 50 && score <= 75) {
      return <div className="result-text">
        <p>You're on the right track! But, it looks like you need help with <strong>{sortedScoresAndText[3].title?.toLowerCase()}</strong> to align church teams better toward your solution.</p>
      </div>
    // } else if (score > 70) {
    } else if (score > 75) {
      return <div className="result-text">
        <p>Great job! It looks like you're aligning church teams well, but the area of <strong>{sortedScoresAndText[3].title?.toLowerCase()}</strong> seems to be making your process less effective.</p>
        {/* <p>Now, invite your team to see if their views align with yours.</p> */}
      </div>
    }
  };

  // const showScoreText = () => {
  //   if(score > 70) {
  //     return <div className="result-text">
  //       Congratulations! It looks like your church is doing a great job at ensuring it is prepared for future evangelism.
  //     </div>
  //   } else if(score > 45) {
  //     return <div className="result-text">
  //       While you are on a journey to ensuring your church is prepared for future evangelism, there's room for reaching out to more of the unchurched in your community.
  //     </div>
  //   } else {
  //     return <div className="result-text">
  //       It looks like your church needs to work towards reaching out to the unchurched in your community.
  //     </div>
  //   }
  // }

  const showInvite = () => {
    if(isMember) {
      return null;
    } else if (props?.showInvite === false) {
      return null;
    } else {
      return ( <InviteInfo /> )
    }
  }

  const showTeamMember = () => {
    if(isMember) {
      return <div className="team-container">
        <h3>Thank you for taking this assessment!</h3>
        Please get in touch with your leader for the next step.<br/><br/>
      </div>
    } else {
      return null;
    }
  }
  const getLeftMargin = () => {
    if (window.innerWidth < 800 && score > 95) {
      return { left: 90 + "%" };
    } else if (window.innerWidth < 800 && score < 5) {
      return { left: 10 + "%" };
    } else {
      return { left: score + "%" };
    }
  };

  // const getLeftMargin = () => {
  //   if(window.innerWidth < 800 && score > 95) {
  //     return { left: 90 + '%'}
  //   } else if(window.innerWidth < 800 && score < 5) {
  //     return { left: 10 + '%'}
  //   } else {
  //     return { left: score + '%'};
  //   }
  // }

  const showSchedule = () => {
    if(isMember) {
      return null;
    } else {
      return <>
        <hr style={{ width: windowWidth > 650 ? "90%" : "70%", maxWidth: "1000px", borderBottom: "1px solid #D8D8D8", margin: "45px auto 45px" }} />
        <div className="schedule-container">
          <h3>Talk to us</h3>
          <p>Let's talk about your results and see how you can effectively align entire church teams around your solution.</p>
          <div className="call-link">
            <Button><a href="https://calendly.com/kurianbk/bleat-quick-call" target="_blank" rel="noreferrer">Book a Call</a></Button>
          </div>
        </div>
      </>
    }
  }
  
  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <h1>Invite your team to take this test</h1>
            <p>As a team, are you in sync about how well you’re aligning church teams around your solution? Find out what every member on your team thinks so that you can plan the right next steps.</p>
            <div className="cta-btn">
              <Link to={"/invite-team/" + id} target="_blank">
                <Button>INVITE YOUR TEAM</Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };

  // const showLegacyInvite = () => {
  //   if(isMember) {
  //     return null;
  //   } else {
  //     // return <div className="invite-legacy-container">
  //     //   Invite your team to take the test and begin to align around an actionable strategy to grow toward a greater level of church health!
  //     //   <div className="cta-btn">
  //     //     <Link to={"/invite-team/" + id} target="_blank">
  //     //       <Button>INVITE MY TEAM</Button>
  //     //     </Link>
  //     //   </div>
  //     // </div>
      
  //     return <div className="testimonial-invite">
  //       <p>Invite your team to take this test along with you and begin to align around the vision for your church’s mission!</p>
  //       <div className="cta-btn">
  //         <Link to={"/invite-team/" + id} target="_blank">
  //           <Button>INVITE YOUR TEAM</Button>
  //         </Link>
  //       </div>
  //     </div>
  //   }
  // }

  const showSourceSection = () => {
    return <div className="source-section">
      <div className="source-info">
        <p>There's a way to turn your greatest challenge into your greatest opportunity and we've got just the plan for you!</p>
        <div className="cta-btn">
          <a href="https://churchfuel.com/join/" target="_blank" rel="noreferrer">
            <Button>SHOW ME HOW</Button>
          </a>
        </div>
      </div>
    </div>
  }

  if (isLoading) {
		return <div className="container results loading"><CircularProgress style={{ color: '#202945' }} size={60} /></div>
	}

  const exportPDF = () => {

    setPdfClass(true);
    document.querySelector('meta[name=viewport]').setAttribute("content", "width=1200");
    document.querySelector("#root").classList.add("pdf-export-results");
    document.querySelector("div.App").setAttribute("id", "pdf-conversion");
    
    html2canvas((document.querySelector("#root")), { scale: 1.5 }).then(canvas => {
      // if you want see your screenshot in body.
      // document.body.appendChild(canvas);
      const imgData = canvas.toDataURL('image/svg');
      // const pdf = new jsPDF("p", "mm", "a4");
      const pdf = new jsPDF({ orientation: "p", unit: "px", format: "a4"});
      const imgProperties = pdf.getImageProperties(imgData);
      
      let pageWidth = pdf.internal.pageSize.getWidth();
      // let pageHeight = (imgProperties.height * pageWidth) / imgProperties.width;
      let pageHeight = pdf.internal.pageSize.getHeight();
      console.log(pageWidth, pageHeight);
      const widthRatio = pageWidth / canvas.width;
      // const heightRatio = pageHeight / canvas.height;
      const heightRatio = ((imgProperties.height * pageWidth) / imgProperties.width) / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;
      console.log(canvasWidth, canvasHeight);
      
      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;
      
      // console.log(canvas);
      // pdf.addImage(imgData, 'PNG', marginX, marginY, canvasWidth, canvasHeight);
      // pdf.addImage(imgData, 'PNG', marginX, 40, canvasWidth, canvasHeight);
      // pdf.addImage(imgData, 'JPEG', 0, 0, pageWidth, pageHeight);
      
      pdf.addImage(imgData, 'SVG', 0, 0, pageWidth, pageHeight, undefined, 'FAST');

      pdf.save("Individual Results.pdf");
      // window.location.reload();
    });
    
    document.querySelector("div.App").removeAttribute("id");
    document.querySelector("#root").classList.remove("pdf-export-results");
    document.querySelector('meta[name=viewport]').setAttribute("content", "width=device-width");
    setPdfClass(false);

    
  }
  const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: subScores[idx] }));
   // remove the last Feedback section from Results and Dashboard
  //  sectionsAndSubScores.pop();

  return (
    <div>
      <div className="container results" id="result-individual">
        
        <div className="details-container" style={{ paddingTop: "1rem" }}>
        
          {showTitleText()}

          <div className="progress-bar">
            <div className="si_ass_result">
              <div className="si_ass_progress">
                <div className="progress-line" style={{ left: score + '%' }}>
                  {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                </div>
                <span className="msi_score" style={getLeftMargin()}>{score}%</span>
              </div>
              <div className="pervalue">
                <h4 className="nimp1" style={{ minWidth: '0%' }}></h4>
                <h4 className="nimp" style={{ minWidth: '50%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '25%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '25%' }}></h4>
                {/* <h4 className="eexpe1" style={{ minWidth: '20%' }}></h4> */}
                {/* <h4 className="eexpe2" style={{ minWidth: '18%' }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              </div>
              
              
              <div className="si_pro_header">
                <h4 className="nimp" style={{ minWidth: '0%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '50%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '25%' }}></h4>
                {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
              </div>
              
              {/* <div className="pervalue-text"> */}
                {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
                {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              {/* </div> */}
             
              <div className="pervalue-text">
                <p className="not-aligned" style={{ minWidth: "50%", fontWeight: score <= 50 ? 800 : 600 }}>Needs <br /> Help</p>

                <p className="some-aligned" style={{ minWidth: "25%", fontWeight: score > 50 && score <= 75 ? 800 : 600 }}>Needs <br /> Improvement</p>

                <p className="high-aligned" style={{ minWidth: "25%", fontWeight: score > 75 ? 800 : 600 }}>Doing <br /> Great!</p>
              </div>

            </div>
          </div>

          {showScoreText()}
          <div className="score-section-container">

            {sectionsAndSubScores?.map(sec => {
              return (
              <div key={sec.section} className="score-section">
                <p className="score-txt">{sec.score}%</p>
                <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={sec?.score} /></div>
                <p className="score-name">{sec.section}</p>
              </div>)
            })}


          </div>
          {!isMember && <div className="invite-your-team">
            <Link to={"/invite-team/" + id} target="_blank">
              <Button>INVITE YOUR TEAM</Button>
            </Link>
          </div>}
          {/* <ResultsSectionBarGraph {...{ subscores }} pdfClass={pdfClass} /> */}

          {/* <div className="result-text">{showResultText()}</div>

          {!isMember && <div className="cta-btn">
            <Link to={"/invite-team/" + id} target="_blank">
              <Button>INVITE YOUR TEAM</Button>
            </Link>
          </div>} */}

          {/* <ResultBarRainbow score={score} /> */}
          {/* {showScoreText()} */}

          {/*<div className="pdf-btn">
            <Button id="pdf-convert-btn" onClick={() => exportPDF()}> <img src={downloadIcon} className="download-icon" alt="download pdf" height="24px" width="24px" />&nbsp; Download Results</Button>
            </div>*/}
        </div>
        
        
      </div>
      
      {/* {showInvite()       } */}
      
      {showLegacyInvite() }

      {!isMember && <Testimonial />}

      {showTeamMember()   }
      {/* {showSourceSection() } */}
      {showSchedule()}

    </div>
  );
}

export default Results;
