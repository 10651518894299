import React from 'react'

const ResultBarRainbow = ({ score }) => {

  // console.log("score", score);

  const getLeftMargin = () => {
    if(window.innerWidth < 800 && score > 95) {
      return { left: 90 + '%'}
    } else if(window.innerWidth < 800 && score < 5) {
      return { left: 10 + '%'}
    } else {
      return { left: score + '%'};
    }
  }

  return (
    <div className="progress-bar">
      <div className="si_ass_result">
        <div className="si_ass_progress">
          <div className="progress-line" style={{ left: `calc(${score}% - 2px)` }}></div>
          <span className="msi_score" style={getLeftMargin()}>{score}%</span>
        </div>
        <div className="pervalue">
          {/* <h4 className="nimp" style={{ minWidth: '45%' }}></h4>
          <h4 className="mexpe" style={{ minWidth: '25.5%' }}></h4>
          <h4 className="eexpe" style={{ minWidth: '29%' }}></h4> */}
          <h4 className="nimp" style={{ minWidth: '50%' }}></h4>
          <h4 className="mexpe" style={{ minWidth: '25%' }}></h4>
          <h4 className="eexpe" style={{ minWidth: '25%' }}></h4>
        </div>
        <div className="si_pro_header">
          <h4 className="nimp" style={{ minWidth: '50%' }}>Needs<br />help</h4>
          <h4 className="mexpe" style={{ minWidth: '25%' }}>Needs<br />improvement</h4>
          <h4 className="eexpe" style={{ minWidth: '25%' }}>Doing<br />great!</h4>
        </div>
      </div>
    </div>
  )
}

export default ResultBarRainbow