import { useState, useEffect, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from '@mui/material/CircularProgress';
import useWindowDimensions from "../Hooks/useWindowDimensions";

import "../App.scss";

const Invite = () => {

  let navigate                              = useNavigate();
  const { id }                              = useParams();
  const { windowWidth }                     = useWindowDimensions();

  const [isSaving, setIsSaving]             = useState(false);
  const [isFormValid, setIsFormValid]       = useState(false);

  // const [list, setList]                     = useState([]);
  const [invitees, setInvitees]             = useState([]);
  const [firstRowName, setFirstRowName]     = useState("");
  const [firstRowEmail, setFirstRowEmail]   = useState("");
  const [inviteBtnColor, setInviteBtnColor] = useState("#202945");
  const [inviteBtnBg, setInviteBtnBg]       = useState("#F7D54E");


  useEffect(() => {
    if(isSaving) {
      setInviteBtnBg("#202945");
      setInviteBtnColor("#FFFFFF");
    } else {
      setInviteBtnBg("#F7D54E");
      setInviteBtnColor("#202945");
    }
  }, [isSaving]);

  useEffect(() => {
    // document.body.style.backgroundColor = "#152036";
    // document.getElementById('assessment-title').style.color = "#1F2A44";
    let invitee = [{
      id: 1,
      name: "",
      email: "",
      showFullName: false,
      showEmail: false
    }]
    setInvitees(invitee);
  }, []);
  
  const submitToApi = (member) => {

    const payload = {
      members: member
    }
    
    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/share/' + id, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        navigate("/invite-team-thanks/" + id, { replace: true });
      } else {
        setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      setIsSaving(false);
      console.error(err);
    })

  }

  const handleSubmit = () => {
    setIsSaving(true);

    let formHasError          = false;
    invitees.forEach(invitee => {
      if(invitee.name === "") {
        invitee.showFullName  = true;
        formHasError          = true; 
      }
      if(invitee.email === "") {
        invitee.showEmail     = true;
        formHasError          = true;
      }
    });
    
    if(formHasError) {
      setTimeout(() => {setIsSaving(false);}, 500);
    } else {      
      submitToApi(invitees);
    }

  }

  const deletePerson = (p) => {    
    setInvitees(invitees.filter((person) => {
      return person.id !== p
    }));
  };

  const addPerson = () => {
    
    let id        = 2;
    if(invitees.length > 0) {
      id          = invitees[invitees.length - 1].id + 1;
    }
    
    let person = {
      id: id,
      name: "",
      email: ""
    }
    setInvitees([...invitees, person]);

  };

  const updateName = (e, id) => {
    let currentMember       = invitees.find(function (obj) { return obj.id === id; });
    currentMember.name      = e.target.value;
  }

  const updateEmail = (e, id) => {
    let currentMember       = invitees.find(function (obj) { return obj.id === id; });
    currentMember.email     = e.target.value;
  }

  const showButton = () => {
    if(isSaving) {
      return <div className="button-progress" style={{ padding: windowWidth > 830 ? "7px 15px" : "4px 10px" }}><CircularProgress style={{ color: '#FFFFFF' }} size={23} /></div>
    }
    return "SEND INVITE";
  }

  const removeError = (id, field, e) => {

    // change active input field title color 
    if(e.target.closest(".form-detail")) {
      document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#414042");
      e.target.closest(".form-detail").querySelector(".input-title").style.color = "#202945";
    }

    let newinvitees = [];
    invitees.forEach(invitee => {
      if(field === "fullName" && invitee.id === id) {
        invitee.showFullName = false;
      }
      if(field === "email" && invitee.id === id) {
        invitee.showEmail = false;
      }
      newinvitees.push(invitee);
    });
    setInvitees(newinvitees);
  }


  return (
    <>
      <div className="container invite">
        <div className="details-container">
          <h1>Invite your team to take this assessment</h1>
          <p className="invite-info">Invite your team and see everyone’s responses on 1 dashboard.</p>

          {
            invitees.map((data, index) => (
              <Fragment key={index}>
                <div className="form _form" key={index}>        
                  <div className="invite-number">{index + 1}.</div>        
                  <div className="form-detail">
                    <p className="input-title">Full name</p>
                    <TextField hiddenLabel placeholder="Simon Peter" onFocus={(e) => {removeError(data.id, "fullName", e)}} onChange={(e) => {updateName(e, data.id)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="invite-field" />
                    { 
                      data.showFullName ?
                        <div className="error-text">Please enter a name.</div>
                      : null
                    }
                  </div>
                  <div className="form-detail">
                    <p className="input-title">Email</p>
                    <TextField hiddenLabel placeholder="speter@petra.church" onFocus={(e) => {removeError(data.id, "email", e)}} onChange={(e) => {updateEmail(e, data.id)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="invite-field" />
                    { 
                      data.showEmail ?
                        <div className="error-text">Please enter a valid email.</div>
                      : null
                    }
                  </div>
                  { data.id != 1 ?
                    <div className="delete-btn">
                      <div onClick={() => deletePerson(data.id)}>
                        <DeleteIcon className="delete-icon" />
                      </div>
                    </div>
                    : null
                  }
                </div>
                <hr className="form-hr" />
              </Fragment>
            ))
          }
          
          <div className="invite-btn-container">
            <div className="invite-another">
              <Button onClick={() => addPerson()} className="add-person-btn">
                + ADD
              </Button>
            </div>

            <Button className="cta-btn" onMouseEnter={() => {
              if(!isSaving) {
                setInviteBtnBg("#202945");
                setInviteBtnColor("#FFFFFF");
              }
            }}
            onMouseLeave={() => {
              if(!isSaving) {
                setInviteBtnBg("#F7D54E");
                setInviteBtnColor("#202945");
              }
            }}
            style={{ backgroundColor: inviteBtnBg, color: inviteBtnColor }}
            // style={{ backgroundColor: isSaving ? "#202945" : "#F7D54E", color: isSaving ? "#FFFFFF" : "#202945" }} 
            disabled={isSaving} onClick={() => handleSubmit()}>{showButton()}</Button>
          </div>

        </div>
      </div>
    </>
  );

};
export default Invite;