import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, IndexRoute } from "react-router-dom";
import TagManager from 'react-gtm-module';

import CircularProgress from '@mui/material/CircularProgress';
 
import Header from './Components/Layout/Header';
import Footer from './Components/Layout/Footer';
 
import Start from './Pages/Start';
import Assessment from './Pages/Assessment';
import AssessmentAdditional from './Pages/AssessmentAdditional';
import Details from './Pages/Details';
import Results from './Pages/Results';
import Invite from './Pages/Invite';
import InviteThankYou from './Pages/InviteThankYou';
import HowItWorks from './Pages/HowItWorks';
import Dashboard from './Pages/Dashboard';
import Experiment from './Pages/Experiment';
import PreAdditionalInfo from './Pages/PreAdditionalInfo';
import PreAdditionalQuestions from './Pages/PreAdditionalQuestions';
import ProgressOne from './Pages/ProgressOne';
import ProgressTwo from './Pages/ProgressTwo';
import ProgressThree from './Pages/ProgressThree';


import { AdditionalQuestionContextProvider } from './Store/AdditionalQuestionContext';
import { AssessmentQuestionContextProvider } from './Store/AssessmentQuestionContext';
import { PreAdditionalQuestionContextProvider } from './Store/PreAdditionalQuestionContext';

// ReactGA.initialize('');
// ReactGA.pageview(window.location.pathname + window.location.search);

const tagManagerArgs = {
  gtmId: 'GTM-5NB8WFS'
}

TagManager.initialize(tagManagerArgs)

function App() {

  const [isLoading, setIsLoading]         = React.useState(false);
  const [name, setName]                   = useState('');

  useEffect(() => {
    getClient()
  }, []);

  const getClient = () => {
    setIsLoading(false);
  }

  if (isLoading) {
		return <div className="container details loading"><CircularProgress style={{ color: '#202945' }} size={60} /></div>
	}

  return (
    <div className="App">
      <BrowserRouter>
      <AssessmentQuestionContextProvider>
      <AdditionalQuestionContextProvider>
      <PreAdditionalQuestionContextProvider>
        <Header />
          <main className="main">
            <Routes>
              <Route path="/" exact index element={<Start setName={setName} />} />
              <Route path="/how-it-works" element={ <HowItWorks /> } />
              <Route path="/start" exact element={<Start setName={setName} />}></Route>
              <Route path="/assessment" exact element={<Assessment />}></Route>
              <Route path="/assessment/:id/review" exact element={<Assessment />}></Route>
              <Route path="/pre-additional-info/:id" exact element={<PreAdditionalInfo />}></Route>
              <Route path="/pre-additional-questions/:id" exact element={<PreAdditionalQuestions />}></Route>
              <Route path="/assessment-additional/:id" exact element={<AssessmentAdditional />}></Route>
              <Route path="/details/:id" exact element={<Details />}></Route>
              <Route path="/results/:id" exact element={<Results />}></Route>
              <Route path="/experiment/" exact element={<Experiment />}></Route>
              <Route path="/invite-team/:id" exact element={<Invite />}></Route>
              <Route path="/invite-team-thanks/:id" exact element={<InviteThankYou />}></Route>
              <Route path="/dashboard/:id" exact element={<Dashboard />}></Route>
              <Route path="/progress-one/:id" exact element={<ProgressOne />}></Route>
              <Route path="/progress-one/" exact element={<ProgressOne />}></Route>
              <Route path="/progress-two/:id" exact element={<ProgressTwo />}></Route>
              <Route path="/progress-two/" exact element={<ProgressTwo />}></Route>
              <Route path="/progress-three/:id" exact element={<ProgressThree />}></Route>
              <Route path="/progress-three/" exact element={<ProgressThree />}></Route>
            </Routes>
          </main>
        <Footer />
      </PreAdditionalQuestionContextProvider>
      </AdditionalQuestionContextProvider>
      </AssessmentQuestionContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
